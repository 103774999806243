<template>
  <div class="container" style="margin-bottom: 30px;">
    <center>
      <div class="sticky-top">
        <img src="images/header.gif" class="img-responsive" style="width: 90%;" />
      </div>
    </center>
    <center>

      <div class="card-container" v-show='alerts.length > 0' style="margin-bottom: 30px;"> <!-- Error Alerts -->
        <div class="card border border-primary" style="width: 90%;">
          <div class="card-body">
            <div v-for='(alert, index) in alerts' :key='alert.msg' :class='alert.type' role="alert">
              {{ alert.msg }}
              <button type="btn btn-danger" class="close" data-dismiss="alert" aria-label="Close" @click='closeAlert(index)'>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-container" style="margin-bottom: 30px;">
          <div class="card border border-primary" style="width: 90%;">
              <div class="card-tite" style="padding-top:20px;">
                  <h2>Thank you for your order</h2>
              </div>
              <div class="card-body">
                  <p class="card-text">We will be reaching out to you shortly to confirm this order.</p>
                  <p class="card-text">If you have any questions, please contact <a href="mailto:bferrarini@scottelectricusa.com">Beth Ferrarini</a> @ (877) 235-0980</p>
              </div>
          </div>
      </div>
    </center>
  </div>
</template>

<script>
/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 
export default {
  name: 'Thanksyou',
  data () {
    return {
      alerts: []
    }
  },
  methods: {
    closeAlert (index) {
      this.alerts.splice(index, 1)
    },
    closeWindow (evt) {
        evt.preventDefault();
        window.close();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.label-spacer {
  margin-bottom: 3px;
}
.fg-spacer {
  margin-bottom: 15px;
}
.price {
  font-size: 1.16em;
}
.tabletop-pad {
  padding-top: 8px;
  text-align: right;
}
</style>
