<template>
  <Berlin />
</template>

<script>
// @ is an alias to /src
/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 
import Berlin from '@/components/Berlin.vue'

export default {
  name: 'Home',
  components: {
    Berlin
  }
}
</script>
