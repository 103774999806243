/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 
import Api from '@/services/Api'

export default {
    submit (payload) {
        return Api().post('submit', payload)
    }
}